<template>
  <p v-if="!isSupported" class="font-bold text-center">
    Browser ondersteunt opnames niet
  </p>
  <div v-else-if="!hasPermission">
    <p class="font-bold text-center">
      Geen microfoon toestemming
    </p>
    <button class="btn info" @click="() => requestPermission()">
      Toestemming vragen
    </button>
  </div>
  <p v-else-if="!microphones?.length" class="font-bold text-center">
    Geen microfoon gevonden
  </p>
  <div v-else class="flex flex-col gap-8 items-start">
    <!--
    <input type="file" accept="audio/*" capture id="recorder" />
    <audio id="player" controls></audio>
      <script>
        const recorder = document.getElementById('recorder');
        const player = document.getElementById('player');

        recorder.addEventListener('change', function (e) {
          const file = e.target.files[0];
          const url = URL.createObjectURL(file);
          // Do something with the audio file.
          player.src = url;
        });
      </script>
    </audio>
    -->

    <UILoading v-show="loading" />
    <button
      v-show="!loading"
      :disabled="!hasPermission || !isSupported || !microphones?.length"
      class="btn"
      :class="{ success: !isActive, error: isActive }"
      @click.stop.prevent="() => isActive ? stopRecording() : startRecording()"
    >
      {{ isActive ? 'Stop recording' : 'Start recording' }}
    </button>

    <div v-show="!loading && !isActive && hasRecordedOnce" class="flex-1 w-full flex flex-col gap-2 items-start" style="max-width: 600px;">
      <audio ref="audio" controls class="h-12 w-full"></audio>
      <button class="btn info" @click="verzenden">
        Nu verzenden
      </button>
    </div>

    <div v-show="!loading && !isActive">
      <div
        v-for="microphone of microphones"
        :key="microphone.deviceId"
        class="px-4 py-2 cursor-pointer btn block mb-2 text-left text-gray-400"
        :class="{ 'text-green-500 font-bold': currentMicrophone === microphone.deviceId }"
        @click="currentMicrophone = microphone.deviceId"
      >
        {{ microphone.label }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useDevicesList, usePermission, useUserMedia } from '@vueuse/core'

import { axiosClient as axios } from '@/plugins/axios'

import UILoading from '@/components/UI/Loading.vue'

const currentMicrophone = ref()

const permissionState = usePermission('microphone')
const hasPermission = computed(() => String(permissionState.value) === 'granted')
// const hasPermission = usePermission('microphone')

const requestPermission = () => {
  const state = usePermission('microphone')
  permissionState.value = state.value
}

const { audioInputs: microphones } = useDevicesList({
  requestPermissions: false,
  onUpdated() {
    if (!currentMicrophone.value || !microphones.value.find(i => i.deviceId === currentMicrophone.value)) {
      currentMicrophone.value = microphones.value[0]?.deviceId
    }
  },
})

const audio = ref()

const isActive = ref(false)
const hasRecordedOnce = ref(false)

const { start, stop, isSupported } = useUserMedia({
  autoSwitch: true,
  constraints: { audio: { deviceId: currentMicrophone } },
})

const loading = ref(false)

const mediaRecorder = ref(null)
const chunks = ref([])

const blob = ref(null)
const audioURL = ref(null)

const startRecording = async () => {
  loading.value = true
  hasRecordedOnce.value = true

  const stream = await start()
  mediaRecorder.value = new MediaRecorder(stream)

  mediaRecorder.value.onstop = () => {
    blob.value = new Blob(chunks.value)

    audioURL.value = URL.createObjectURL(blob.value)
    if (audio.value) audio.value.src = audioURL.value

    mediaRecorder.value = null
    chunks.value = []
  }

  mediaRecorder.value.ondataavailable = e => {
    chunks.value.push(e.data)
  }

  chunks.value = []
  isActive.value = true
  mediaRecorder.value.start()
  loading.value = false
}

const stopRecording = async () => {
  loading.value = true
  await stop()
  chunks.value = []
  isActive.value = false
  if (mediaRecorder.value) mediaRecorder.value.stop()
  loading.value = false
}

const verzenden = async () => {
  loading.value = true

  const formdata = new FormData()
  formdata.append('file', blob.value, 'file.webm')

  axios.post('/api/dashboard/voice/save', formdata, { crossDomain: true }).finally(() => {
    loading.value = false
  })
}
</script>
